<template>
    <base-layout title="Daily Report" page-default-back-link="/tabs/store">
    <ion-grid>
             <ion-row>
                 <ion-col offset-md="2" size-md="8" offset-lg="3" size-lg="6" offset-sm="0" size-sm="12">
        <ion-card>
            <ion-card-header class="ion-text-center"> <h2>Orders Placed Today</h2> </ion-card-header>
                    <ion-list>
                        <ion-item v-for="(order, index) in todayOrders" :key="order.id">
                            
                            <ion-badge color="primary" slot="start"> {{index + 1}}</ion-badge>
                            <ion-label v-if="order._showDetails != true">                          
                                <h2 style="padding-left: 5px; font-weight: bold">{{order.userName}}</h2>
                                
                                <ion-chip style="font-weight: bold; color: black" v-for="(orderItem, index) in order.items" :key="index">
                                    {{orderItem.name}}
                                </ion-chip>                               
                                <h4 style="padding-left: 5px; font-weight: bold; color: green">{{priceFormat(totalPrice(order.id))}}</h4>
                                <div style="padding-left: 5px;"> {{order.status}} </div>
                                <h4 style="padding-left: 5px;">{{ timeStamp(order.formattedDate) }}</h4>
                            </ion-label>
                            <div v-else>
                                <ion-card color="primary" class="ion-text-center" v-for="orderItem in order.items" :key="orderItem.id">
                                    <ion-label ><h1>{{orderItem.name}}</h1></ion-label> - <ion-label v-if="orderItem.status != 'Cancelled'"><h2>{{priceFormat(orderItem.price)}}</h2></ion-label> 
                                    <ion-label v-else style="font-weight: bold; color: red">Item Cancelled</ion-label>
                                    <ion-label v-if="order.paid">Order Has Been Paid</ion-label>
                                </ion-card>
                            </div> 


                            <div class="ion-text-center">
                                <ion-button :id="order.id" fill="clear" size="large" @click="toggleOrderDetails(order)">
                                    <ion-icon :color="colorFormat(order)" :icon="getIcon(order)"></ion-icon>
                                </ion-button>  
                                <br>
                                <ion-button v-if="!order.paid && order.status != 'Cancelled'" color="medium" @click="markPaid(order.id)">Mark Paid</ion-button>
                                <ion-button v-if="order.paid && order.status != 'Cancelled'" color="medium" @click="markUnpaid(order.id)">Mark Unpaid</ion-button>
                            </div> 
                        </ion-item>
                    </ion-list><br><br>
        </ion-card>



        <ion-card>
            <ion-card-header class="ion-text-center"> <h2>Select A Date</h2> </ion-card-header>
                <ion-card-content class="ion-text-center">
                    <ion-button size="small" color="medium" fill="outline">
                        <ion-datetime id="choose" display-format="MMMM DD, YYYY" placeholder="None" min="2021" v-on:ion-change="printReport($event.target.value)"></ion-datetime>
                        <h1 style="opacity:0"> l </h1>
                    </ion-button>
            </ion-card-content>

            <ion-list style="">
                <ion-item v-for="(order, index) in selectedOrders" :key="order.id">
                <ion-badge color="primary" slot="start">{{ index + 1 }}</ion-badge> 
                <ion-label v-if="order._showDetails != true">
                    <h2 style="padding-left: 5px; font-weight: bold">{{order.userName}}</h2>
                    <ion-chip style="font-weight: bold; color: black" v-for="(orderItem, index) in order.items" :key="index">
                                    {{orderItem.name}}
                                </ion-chip>
                    <h4 style="padding-left: 5px">{{ timeStamp(order.formattedDate) }}</h4>
                    <h4 style="padding-left: 5px; font-weight: bold; color: green">{{priceFormat(totalPrice(order.id))}}</h4>
                    <div style="padding-left: 5px"> {{order.status}} </div>
                </ion-label>

            <div v-else>
                <ion-card color="primary" class="ion-text-center" v-for="orderItem in order.items" :key="orderItem.id">
                    <ion-label :style="checkStatus(orderItem)">{{orderItem.name}}</ion-label> - <ion-label v-if="orderItem.status != 'Cancelled'">{{priceFormat(orderItem.price)}}</ion-label> 
                    <ion-label v-else style="font-weight: bold; color: red">Item Cancelled</ion-label>
                    <ion-button v-if="!order.paid && order.status != 'Cancelled'" color="medium" @click="markPaid(order.id)">Mark as Paid</ion-button>
                    <ion-label v-if="order.paid">Order Has Been Paid</ion-label>
                </ion-card>
            </div> 

            <ion-button :id="order.id" fill="clear" size="large" @click="toggleOrderDetails(order)">
                <ion-icon :color="colorFormat(order)" :icon="getIcon(order)"></ion-icon>
            </ion-button>              
        </ion-item>
    </ion-list>
 </ion-card>




        <ion-item-divider></ion-item-divider>
        <ion-button style="padding-left: 20px" color="medium" id="button" @click="viewAllOrders">View All Orders</ion-button>

        <ion-list v-if="showAllOrders">
            <ion-item v-for="(order, index) in orders" :key="order.id">
                <ion-badge color="primary" slot="start">{{index + 1}}</ion-badge>
                    <ion-label>
                        <h2>{{order.userName}}</h2>
                        <h4>{{ stringify(order.formattedDate) }}</h4>
                        <p>{{ timeStamp(order.formattedDate)}} </p>
                    </ion-label>
                <ion-icon :color="colorFormat(order)" :icon="getIcon(order)"></ion-icon>
            </ion-item>
        </ion-list>
    </ion-col>
</ion-row>
</ion-grid>
        
    </base-layout>
</template>

<script>

const enUSFormatter = new Intl.DateTimeFormat('en-US', {
    year:  'numeric',
    month: 'long',
    day:   'numeric',
});

import { checkboxOutline, alertCircleOutline, closeCircleOutline } from 'ionicons/icons';

export default {
    data() {
        return {
            input: "",
            showList: true,
            showAllOrders: false
        }
    },
    computed: {
        selectedDate() {
            let selDate = this.input
            return selDate
        },
        today() {
            const nowDate = new Date()
            return enUSFormatter.format(nowDate)
        },
        orders() {
            return this.$store.getters.allOrders
        },
        todayOrders() {
            if (this.orders) {
                let unfiltered = this.orders
                var filtered = []
                for (let i = 0; i < unfiltered.length; i++) {
                    let num = unfiltered[i].formattedDate
                    let theDate = enUSFormatter.format(num)
                    if (theDate == this.today) {
                        filtered.push(unfiltered[i])
                    }
                }
            
            }
            return filtered
        },
        selectedOrders() {
            if (this.orders) {
                let unfiltered = this.orders
                var filtered = []
                for (let i = 0; i < unfiltered.length; i++) {
                    let num = unfiltered[i].formattedDate
                    let theDate = enUSFormatter.format(num)
                    if (theDate == this.selectedDate) {
                        filtered.push(unfiltered[i])
                    }
                }
                
            }
            return filtered
            }
        
    },
    mounted() {
    if (this.orders == null) {
      this.$store.dispatch('loadAllOrders');
    }
    
  },
  methods: {
      viewAllOrders() {
          let text = document.getElementById("button").innerHTML
          if (text == "View All Orders") {
              this.showAllOrders = true
              document.getElementById("button").innerHTML = "Hide All Orders"
          } else if (text == "Hide All Orders") {
              this.showAllOrders = false
              document.getElementById("button").innerHTML = "View All Orders"
          }
      },
      checkStatus(x) {
            if (x.status != "Cancelled") {
                return ""
            } else {
                return "text-decoration: line-through"
            }
      },
      markPaid(orderId) {
          this.$store.dispatch('togglePaidStatus', {orderId: orderId, paid: true});
      },
      markUnpaid(orderId) {
          this.$store.dispatch('togglePaidStatus', {orderId: orderId, paid: false});
      },
      colorFormat(x) {
        if (x.paid == true) {
            return 'success'
        } else if (x.status == 'Cancelled') {
            return 'danger'
        } else if (x.paid == false) {
            return 'background'
        }
      },

    getIcon(x) {
        if (x.paid == true) {
            return checkboxOutline;
        } else if (x.status == 'Cancelled') {
            return closeCircleOutline;
        } else if (x.paid == false) {
            return alertCircleOutline;
        }
      },
      totalPrice(x) {
            let price = 0
            let orders = this.orders
            for (let i = 0; i < orders.length; i++) {
                if (orders[i].id == x) {
                    let orderItems = orders[i].items
                    for (let u = 0; u < orderItems.length; u ++) {
                        if (orderItems[u].status != "Cancelled")
                            price = price + orderItems[u].price  
                    }
                }
            }
            return price
        },
      priceFormat(x) {
        let formatted = "$" + x.toFixed(2)
        return formatted
      },
      toggleOrderDetails(order) {
          if(order._showDetails == null) {
              order._showDetails = false;
          }
          order._showDetails=!order._showDetails;
      },
      printReport(x) {
        let date = new Date(x).getTime()
        let formatted = enUSFormatter.format(date)
        this.input = formatted
      },
      timeStamp(x) {
        let fulldate = new Date(x - 18000000)
        let fulldatestring = JSON.stringify(fulldate)
        let start = fulldatestring.indexOf("T") + 1
        let end = fulldatestring.indexOf(".")
        let time = fulldatestring.slice(start, end)

        time = time.split(':'); 

        let hours = Number(time[0]);
        let minutes = Number(time[1]);
        let seconds = Number(time[2]);

        let timeValue;

        if (hours > 0 && hours <= 12) {
        timeValue= "" + hours;
        } else if (hours > 12) {
        timeValue= "" + (hours - 12);
        } else if (hours == 0) {
        timeValue= "12";
        }
        
        timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
        timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
        timeValue += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM
        //after
        return timeValue
      },
      stringify(x) {
        // let date = new Date(x)
        // console.log(x);
        // // let formatted = date.toString();
        let usFormat = enUSFormatter.format(x)

        return usFormat
      }
  }
}
</script>